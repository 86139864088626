// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import HeaderSlogan from 'components/Contentful/HeaderSlogan'
import JobDescription from 'components/Contentful/JobDescription'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Blindbewerbung - Komm in unser Team!"
        meta={[
          { name: 'description', content: 'Du willst als Teamplayer die Heizungs- und Solarbranche nachhaltig mitgestalten? Überzeuge uns durch deine Blindbewerbung. Wir freuen uns!' },
          { name: 'keywords', content: 'Blindbewerbung, Jobs' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='3F1Zcss2snioNIAOkR6sta-5KU0wY4y7Swe4yIqc2A4Se'
          id=''
        >
          <HeaderSlogan
            {...{
    "type": "headerSlogan",
    "id": "5KU0wY4y7Swe4yIqc2A4Se",
    "updatedAt": "2020-02-07T08:48:32.417Z",
    "title": "Arbeite mit uns",
    "subtitle": "Du magst Menschen?\nLust auf Neues?\nDas trifft sich gut.",
    "text": "<p>Heizungsmacher AG ist eines der modernsten Unternehmen im Bereich der Gebäudetechnik. Mit unseren beiden Brands «Heizungsmacher» und «Solarmacher» unterstützen wir unsere Kunden beim Ersatz alter Heizsysteme in Einfamilienhäuser durch moderne Wärmepumpen. Zugleich erweitern wir die eigenen Energieversorgungen mit qualitativ hochwertigen Photovoltaikanlagen.</p>\n",
    "link": {
        "type": "link",
        "id": "2l34rq11IcumgEygwgSQWS",
        "updatedAt": "2018-08-07T14:03:45.982Z",
        "label": "Alle offenen Stellen",
        "page": {
            "slug": "jobs"
        }
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='3F1Zcss2snioNIAOkR6sta-6IpzudGAA3y7Y1BXY9uVUT'
          id=''
        >
          <JobDescription
            {...{
    "type": "jobDescription",
    "id": "6IpzudGAA3y7Y1BXY9uVUT",
    "updatedAt": "2021-07-22T12:05:30.383Z",
    "title": "Blindbewerbung",
    "text": "<h1>Blindbewerbung</h1>\n<p>bei Bedarf\n80%-100%\nArbeitsgebiet: irgendwo in der Deutschschweiz</p>\n<h2>Lust bei uns zu arbeiten?</h2>\n<p>Wir sind immer interessiert an interessanten Dossiers von Menschen mit Potenzial.</p>\n<p>Du bist ein lösungsorientierter Machertyp, organisatorisch stark und du kommunizierst stilsicher? Du willst als Teamplayer die Heizungs- und Solarbranche nachhaltig mitgestalten und bist dir nicht zu Schade auch mal an einer unserer Anlagen eine Schraube anzuziehen? Wenn du zudem Bock hast, Gas zu geben und unsere Firma jeden Tag voranzubringen, dann sollten wir uns kennenlernen.</p>\n<p>Wenn du im weiteren Erfahrung in der Heizungs- und Solarbranche hast, sei dies als Projektleiter, Verkaufsberater oder ähnlichem, dann sind deine Chancen sogar schon ziemlich gross.</p>\n<p>Also hau in die Tasten! Gerne erwartet Marcel Bachmann deine Blindbewerbung mit Motivationsschreiben und Lebenslauf an <a href=\"mailto:karriere@heizungsmacher.ch\">karriere@heizungsmacher.ch</a>.</p>\n",
    "workload": "80% - 100%",
    "location": "Irgendwo in der Deutschschweiz",
    "jobLocation": "Irgendwo in der Deutschschweiz"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
